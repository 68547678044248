<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :product-disable="true"
      :filter-names="filterNames"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="isView"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">

        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-rates
            v-if="isCreate"
            :filter-options="options"
          />

          <edit-rates
            v-if="(isView || isEdit) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateRates: () => import("./dialogs/CreateRates.vue"),
    EditRates: () => import("./dialogs/EditRates.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      module_name: "csmsRates", //vuex module name which define in Store Index
      permissions: {
        view: "rates.view",
        create: "rates.create",
        edit: "rates.edit",
        delete: "rates.delete",
      },
      //
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsRates/getLoading",
      meta: "csmsRates/getMeta",
      list: "csmsRates/getList",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },
        ...(this.authUser.user_nature == "fleet"
          ? [
            {
              text: this.$t("manufacturer"),
              align: "left",
              sortable: false,
              value: "tenant_name",
            },
          ]
          : []),
        {
          text: this.$t("type"),
          align: "left",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("durationMin"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("tax"),
          align: "left",
          sortable: false,
          value: "tax",
        },
        {
          text: this.$options.filters.currencyFormat(
            this.$t("price"),
            this.authUser
          ),
          align: "left",
          sortable: false,
          value: "price",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "rate_status",
        },
        ...(this.isView || this.isEdit || this.isDelete
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ];

      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return ["search", "status"];
      // if (this.authUser.isAdmin) {
      // } else {
      //   return [];
      // }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "rates",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      const params = { ...this.options };
      params.status = params.status
        ? params.status == "active"
          ? "1"
          : "0"
        : null;
      await this.$store.dispatch("csmsRates/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("csmsRates/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>